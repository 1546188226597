import React from 'react'
import { PropTypes } from 'prop-types'
import {
  Typography, Box, makeStyles,
} from '@material-ui/core'

import EyebrowWithType from './EyebrowWithType'
import RiseButton from './RiseButton'
import ContentIcon from './graphics/ContentIcon'
import { withEllipsis } from '../utils/string'
import TitleHighlight from './TitleHighlight'
import makeTexture from './graphics/Texture'

const useStyles = makeStyles((theme) => ({
  main: {
    '&$entered $word': {
      opacity: 1,
    },
    '&$entered $titleContainer': {
      transform: 'translate(0px,0px)',
    },
    '&$entered $descriptionContainer': {
      opacity: 1,
      transform: 'translate(0px,0px)',
    },
    '&$entered $btnContainer': {
      opacity: 1,
      transform: 'translate(0px,0px)',
    },
    '&$entered $eyebrowContainer': {
      opacity: 1,
      transform: 'translate(0px,0px)',
    },
    '&$entered $underlineContainer': {
      width: '100%',
    },
  },
  eyebrowContainer: {
    opacity: 0,
    transition: 'all 0.6s ease',
  },
  heroTitle: {
    fontFamily: 'permanent_markerregular',
    fontSize: '3.125rem',
    lineHeight: '100%',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.875rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '4.5rem',
    },
  },
  titleContainer: {
    transform: 'translate(-20px,20px)',
    transition: 'transform 0.9s ease',
  },
  word: {
    opacity: 0,
    transition: 'opacity 0.9s ease',
  },
  entered: {},
  descriptionContainer: {
    opacity: 0,
    transform: 'translate(0px,8px)',
    transition: 'all 0.6s ease',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(5.75),
    },
  },
  btnContainer: {
    opacity: 0,
    transform: 'translate(0px,8px)',
    transition: 'all 0.6s ease',
  },
  description: {
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
    },
  },
  underline: {
    backgroundImage: 'url("/underline.svg")',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    color: '#f2f3f8',
  },
  underlineContainer: {
    width: '0%',
    overflow: 'hidden',
    transition: 'all 0.6s ease',
  },
}))

const HomeHeroCopy = ({
  type,
  title,
  titleUnderline,
  description,
  buttons,
  entered,
  setBtnHovered,
  texture,
}) => {
  const classes = useStyles()
  const Texture = makeTexture(texture)

  return (
    <Box
      style={{ transform: 'translateZ(0)' }}
      className={`${classes.main} ${entered ? classes.entered : ''}`}
    >
      <Box mb={{ xs: 1.25, md: 3 }} className={classes.eyebrowContainer}>
        <EyebrowWithType type={type} />
      </Box>
      {entered && (
      <TitleHighlight
        title={title}
        highlightIndex={titleUnderline - 1}
      />
      )}
      <Box
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        display={{ md: 'none' }}
        zIndex="-1"
      >
        <Texture variation="blue" />
      </Box>
      <Box
        maxWidth={550}
        mt={{ xs: 3.75, md: 5 }}
        style={{
          transitionDelay: `${title.split(' ').length * 280}ms`,
        }}
        className={classes.descriptionContainer}
      >
        <Typography className={classes.description} variant="body1" style={{ lineHeight: '150%' }}>
          {withEllipsis(description, 285)}
        </Typography>
      </Box>
      <Box
        display="flex"
        className={classes.btnContainer}
        style={{
          textTransform: 'capitalize',
          transitionDelay: `${title.split(' ').length * 280 + 100}ms`,
        }}
      >
        {buttons.map(({
          text, type: buttonType, id, to,
        }, i) => {
          const startIcon = (
            <Box display="flex" zIndex={1}>
              <ContentIcon type={buttonType} />
            </Box>
          )

          return i === 0 ? (
            <Box
              mr={3.5}
              key={id}
              onMouseEnter={() => setBtnHovered(true)}
              onMouseLeave={() => setBtnHovered(false)}
            >
              <RiseButton to={to} type={buttonType} startIcon={startIcon}>
                {text}
              </RiseButton>
            </Box>
          ) : (
            <RiseButton type={buttonType} flat startIcon={startIcon}>
              {text}
            </RiseButton>
          )
        })}
      </Box>
    </Box>
  )
}

HomeHeroCopy.propTypes = {
  texture: PropTypes.shape({
    blue: PropTypes.shape({}),
    green: PropTypes.shape({}),
    grey: PropTypes.shape({}),
  }).isRequired,
  type: PropTypes.string.isRequired,
  entered: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleUnderline: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setBtnHovered: PropTypes.func.isRequired,
}

export default HomeHeroCopy
