import React, { useState, useRef, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import {
  Box, makeStyles,
} from '@material-ui/core'
import Triangle from './graphics/Triangle'
import Circle from './graphics/Circle'
import HomeHeroCopy from './HomeHeroCopy'
import ImageRiseHomeHero from './animated-images/ImageRiseHomeHero'
import makeTexture from './graphics/Texture'
import GlobalContext from '../context/GlobalContext'


const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    position: 'relative',
  },
  heroCopyContainer: {
    width: '100%',
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: '60% 0 60px 0',
      width: '100%',
      '&:before:': {
        content: '',
        display: 'block',
        width: '100vw',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        background: 'linear-gradient(45deg, #0C0F11, transparent)',
      },
    },
  },
  heroImageContainer: {
    overflowX: 'hidden',
    width: '100%',
    maxWidth: '900px',
    right: 0,
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: '1080px',
      top: '0',
      paddingTop: '50px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
      maxWidth: '1208px',
    },
  },
  overlayMobile: {
    display: 'block',
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    background: 'linear-gradient(0deg, #0C0F11 30%, transparent 60%)',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  overlayDesktop: {
    display: 'block',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      background:
        'linear-gradient(-90deg, rgba(12, 15, 17, 0) 20%, #0C0F11 100%)',
    },
  },
  shape: {
    transform: 'translate(-20px, 20px)',
    opacity: 0,
    transition: 'all 1s ease 0.8s',
    '&$active': {
      transform: 'translate(0,0)',
      opacity: 0.5,
    },
  },
  circleIconContainer: {
    transition: 'all 1s ease 0.8s',
    right: '0%',
    width: '154px',
    height: '154px',
    bottom: '0%',
    [theme.breakpoints.up('md')]: {
      right: '4%',
      width: '264px',
      height: '264px',
      bottom: '18%',
    },
  },
  triangle: {
    width: '150px',
    height: '150px',
    left: '-12%',
    top: '3%',
    [theme.breakpoints.up('md')]: {
      right: '520px',
      width: '320px',
      height: '320px',
      left: 'initial',
      top: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      right: '520px',
    },
  },
  active: {},
}))

const HomeHero = ({
  texture,
  type,
  title,
  titleUnderline,
  description,
  buttons,
  image,
  slug,
}) => {
  const classes = useStyles()
  const heroRef = useRef(null)
  const triangleRef = useRef(null)
  const circleRef = useRef(null)
  const [animationStarted, setAnimationStarted] = useState(false)
  const [heroIsLoaded, setHeroIsLoaded] = useState(false)
  const [btnHovered, setBtnHovered] = useState(false)
  useEffect(() => {
    let textAnimTimeout = null
    let beginHeroAnimTimeout = null
    if (heroIsLoaded) {
      textAnimTimeout = setTimeout(() => {
        setAnimationStarted(true)
      }, 200)
      beginHeroAnimTimeout = setTimeout(() => {
        heroRef.current.startAnimation()
      }, 900)
    }
    return () => {
      clearTimeout(beginHeroAnimTimeout)
      clearTimeout(textAnimTimeout)
    }
  }, [heroIsLoaded])

  const Texture = makeTexture(texture)

  return (
    <GlobalContext.Consumer>
      {({ setLoaded }) => (
        <Box display="flex" alignItems={{ md: 'center' }} className={classes.main}>
          <Box
            mt={{ xs: 'auto', md: 0 }}
            mb={{ xs: '30px', sm: 0, md: 0 }}
            px={2.5}
            className={classes.heroCopyContainer}
            style={{ transform: 'translateZ(0)' }}
          >
            <HomeHeroCopy
              texture={texture}
              type={type}
              title={title}
              titleUnderline={titleUnderline}
              description={description}
              buttons={buttons}
              slug={slug}
              entered={animationStarted}
              setBtnHovered={setBtnHovered}
            />
            <Box
              position="absolute"
              top="0"
              bottom="0"
              width="50%"
              maxWidth="769px"
              zIndex="10"
              style={{ pointerEvents: 'none' }}
            >
              <Texture variation="grey" />
            </Box>
          </Box>
          <Box
            position="absolute"
            zIndex="-1"
            className={classes.heroImageContainer}
          >
            <Box>
              <ImageRiseHomeHero
                ref={heroRef}
                image={image}
                themeType={type}
                hasEnterAnim
                isActive={btnHovered}
                onHeroImageLoad={() => {
                  setLoaded(true)
                  setHeroIsLoaded(true)
                }}
              />
            </Box>
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              zIndex="1"
              className="overlaycontainer"
            >
              <Box className={`${classes.overlayDesktop} overlay`} />
            </Box>
            <Box
              position="absolute"
              top="0"
              right="0"
              width="50%"
              maxWidth="948px"
              zIndex="10"
              style={{ pointerEvents: 'none' }}
            >
              <Texture variation="grey" />
            </Box>
            <Box
              position="absolute"
              className={`${classes.shape} ${classes.triangle} ${
                animationStarted ? classes.active : ''
              }`}
              ref={triangleRef}
            >
              <Triangle />
            </Box>
            <Box
              className={`${classes.shape} ${classes.circleIconContainer} ${
                animationStarted ? classes.active : ''
              }`}
              position="absolute"
              ref={circleRef}
            >
              <Circle />
            </Box>
          </Box>
          <Box className={classes.overlayMobile} />
        </Box>
      )}
    </GlobalContext.Consumer>
  )
}

HomeHero.propTypes = {
  texture: PropTypes.shape({
    blue: PropTypes.shape({}),
    green: PropTypes.shape({}),
    grey: PropTypes.shape({}),
  }).isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleUnderline: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  image: PropTypes.shape({}).isRequired,
}

export default HomeHero
