import React from 'react'
import PropTypes from 'prop-types'
import CardGrid from './CardGrid'
import ContentCard from './ContentCard'
import { TYPES } from '../utils/constants'

const NewReleases = ({ content }) => (
  <CardGrid title="New Releases" titleMb={11}>
    {content.slice(0, 6).map(({ node: { frontmatter, fields } }) => (
      <ContentCard
        key={frontmatter.id}
        target={frontmatter.externalUrl ? '_blank' : '_self'}
        to={frontmatter.externalUrl ? frontmatter.externalUrl : fields.slug}
        thumbnail={frontmatter.heroImg ? frontmatter.heroImg : frontmatter.thumbnail}
        episodeNumber={fields.autoEpisodeNumber}
        type={TYPES[frontmatter.templateKey]}
        title={frontmatter.title}
        description={frontmatter.description}
        mediaDuration={frontmatter.mediaDuration}
        subtitle={fields.seriesTitle}
        toSecondary={fields.seriesTitleSlug}
      />
    ))}
  </CardGrid>
)

NewReleases.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      templateKey: PropTypes.string,
      description: PropTypes.string,
      heroImg: PropTypes.string,
      publisher: PropTypes.string,
      mediaUrl: PropTypes.string,
      mediaDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
}

NewReleases.defaultProps = {
  content: [],
}

export default NewReleases
