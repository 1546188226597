import React from 'react'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const Listen = () => (
  <>
    <Hidden xsUp>
      <p>Listen</p>
    </Hidden>
    <SvgIcon viewBox="0 7 130 130">
      <path
        d="M119.194 67.9324C119.194 53.3634 113.389 39.3912 103.057 29.0893C92.7238 18.7875 78.7097 13 64.0971 13C49.4845 13 35.4704 18.7875 25.1377 29.0893C14.8051 39.3912 9.00024 53.3634 9.00024 67.9324V111.878C9.00024 112.85 9.38723 113.781 10.0761 114.468C10.7649 115.155 11.6992 115.541 12.6734 115.541H31.039C32.0132 115.541 32.9474 115.155 33.6363 114.468C34.3251 113.781 34.7121 112.85 34.7121 111.878V75.2567C34.7121 74.2855 34.3251 73.354 33.6363 72.6672C32.9474 71.9804 32.0132 71.5946 31.039 71.5946H16.3465V67.9324C16.3465 55.306 21.3773 43.1967 30.3323 34.2684C39.2873 25.3402 51.4329 20.3243 64.0971 20.3243C76.7614 20.3243 88.9069 25.3402 97.8619 34.2684C106.817 43.1967 111.848 55.306 111.848 67.9324V71.5946H97.1553C96.1811 71.5946 95.2468 71.9804 94.558 72.6672C93.8691 73.354 93.4821 74.2855 93.4821 75.2567V111.878C93.4821 112.85 93.8691 113.781 94.558 114.468C95.2468 115.155 96.1811 115.541 97.1553 115.541H115.521C116.495 115.541 117.429 115.155 118.118 114.468C118.807 113.781 119.194 112.85 119.194 111.878V67.9324ZM27.3659 78.9189V108.216H16.3465V78.9189H27.3659ZM100.828 108.216V78.9189H111.848V108.216H100.828Z"
        fill="#307EDA"
      />
    </SvgIcon>
  </>
)

const Recent = () => (
  <>
    <Hidden xsUp>
      <p>Recent</p>
    </Hidden>
    <SvgIcon viewBox="0 0 128 128">
      <path
        d="M64 9.59998C34.0029 9.59998 9.59998 34.0029 9.59998 64C9.59998 93.9969 34.0029 118.4 64 118.4C93.9971 118.4 118.4 93.9969 118.4 64C118.4 34.0029 93.9971 9.59998 64 9.59998ZM64 17.561C89.6946 17.561 110.439 38.3054 110.439 64C110.439 89.6946 89.6946 110.439 64 110.439C38.3054 110.439 17.561 89.6946 17.561 64C17.561 38.3054 38.3054 17.561 64 17.561ZM64 24.1951C61.8017 24.1951 60.0195 25.9773 60.0195 28.1756V64C60.0195 65.472 60.8217 66.7527 62.0097 67.4414L90.7438 84.0267C92.6475 85.1259 95.0764 84.4795 96.1756 82.5756C97.2747 80.6719 96.6283 78.2429 94.7243 77.1438L67.9805 61.7194V28.1756C67.9805 25.9773 66.1984 24.1951 64 24.1951Z"
        fill="#000"
      />
    </SvgIcon>
  </>
)

const Watch = () => (
  <>
    <Hidden xsUp>
      <p>Watch</p>
    </Hidden>
    <SvgIcon viewBox="3 2 124 124">
      <path
        d="M117.169 16H10.8307C8.17221 16 6.3999 17.7455 6.3999 20.3636V107.636C6.3999 110.255 8.17221 112 10.8307 112H117.169C119.828 112 121.6 110.255 121.6 107.636V20.3636C121.6 17.7455 119.828 16 117.169 16ZM15.2614 103.273V24.7273H112.738V103.273H15.2614Z"
        fill="#FF5B16"
      />
      <path
        d="M83.9379 60.0723L52.9225 42.6178C52.0364 42.1814 51.5933 42.1814 50.7071 42.1814C48.0487 42.1814 46.2764 43.9269 46.2764 46.545V81.4541C46.2764 84.0723 48.0487 85.8178 50.7071 85.8178C51.5933 85.8178 52.0364 85.8178 52.9225 85.3814L83.9379 67.9268C84.8241 67.4905 85.2671 67.0541 85.7102 66.1814C86.5964 63.9996 86.1533 61.3814 83.9379 60.0723ZM55.1379 74.0359V53.9632L72.861 63.9996L55.1379 74.0359Z"
        fill="#FF5B16"
      />
    </SvgIcon>
  </>
)

const Read = () => (
  <>
    <Hidden xsUp>
      <p>Read</p>
    </Hidden>
    <SvgIcon viewBox="0 0 128 128">
      <rect
        x="12.8"
        y="19.2"
        width="102.4"
        height="9.6"
        rx="4.8"
        fill="#40A36D"
      />
      <rect
        x="12.8"
        y="44.7999"
        width="102.4"
        height="9.6"
        rx="4.8"
        fill="#40A36D"
      />
      <rect
        x="12.8"
        y="70.3999"
        width="102.4"
        height="9.6"
        rx="4.8"
        fill="#40A36D"
      />
      <rect x="12.8" y="96" width="80" height="9.6" rx="4.8" fill="#40A36D" />
    </SvgIcon>
  </>
)

const MixedMedia = () => (
  <>
    <Hidden xsUp>
      <p>Mixed Media</p>
    </Hidden>
    <SvgIcon viewBox="0 -3 130 130">
      <path
        d="M114.226 21.4713H12.7742C10.1484 21.4713 8 19.3402 8 16.7356C8 14.131 10.1484 12 12.7742 12H114.226C116.852 12 119 14.131 119 16.7356C119 19.3402 116.852 21.4713 114.226 21.4713Z"
        fill="#6630DA"
      />
      <path
        d="M64.0968 64.092H12.7742C10.1484 64.092 8 61.961 8 59.3564C8 56.7518 10.1484 54.6207 12.7742 54.6207H64.0968C66.7226 54.6207 68.871 56.7518 68.871 59.3564C68.871 61.961 66.7226 64.092 64.0968 64.092Z"
        fill="#6630DA"
      />
      <path
        d="M114.778 104.345H66.2222C63.9 104.345 62 102.214 62 99.6092C62 97.0046 63.9 94.8735 66.2222 94.8735H114.778C117.1 94.8735 119 97.0046 119 99.6092C119 102.214 117.1 104.345 114.778 104.345Z"
        fill="#6630DA"
      />
      <path
        d="M114.345 54.1472L89.758 40.0587C87.4903 38.7564 84.7451 40.4139 84.7451 42.9001V71.0771C84.7451 73.6817 87.4903 75.2208 89.758 73.9185L114.345 59.83C116.613 58.5277 116.613 55.4495 114.345 54.1472Z"
        fill="#6630DA"
      />
      <path
        d="M27.0968 115C37.6436 115 46.1936 106.519 46.1936 96.0575C46.1936 85.5958 37.6436 77.115 27.0968 77.115C16.5499 77.115 8 85.5958 8 96.0575C8 106.519 16.5499 115 27.0968 115Z"
        fill="white"
      />
    </SvgIcon>
  </>
)

const ContentIcon = ({ type }) => {
  switch (type) {
    case 'listen':
      return <Listen />
    case 'recent':
      return <Recent />
    case 'watch':
      return <Watch />
    case 'mixed':
      return <MixedMedia />
    case 'series':
    case 'read':
    default:
      return <Read />
  }
}

ContentIcon.propTypes = {
  type: PropTypes.oneOf([
    'listen',
    'read',
    'watch',
    'mixed',
    'series',
    'recent',
  ]).isRequired,
}

export default ContentIcon
