import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { PropTypes } from 'prop-types'
import {
  Container, Box, Typography, makeStyles,
} from '@material-ui/core'
import { TransitionState } from 'gatsby-plugin-transition-link'
import makeTexture from '../components/graphics/Texture'

import Layout from '../components/Layout'
import HomeHero from '../components/HomeHero'
import FeaturedSeries from '../components/FeaturedSeries'
import NewsletterBlock from '../components/NewsletterBlock'
import RiseButton from '../components/RiseButton'
import NewReleases from '../components/NewReleases'
import AboutBlock from '../components/AboutBlock'
import { TYPES } from '../utils/constants'
import IntersectBox from '../components/IntersectBox'
import seriesTitleSlug from '../utils/seriesTitleSlug'


const useStyles = makeStyles(() => ({
  main: {
    transition: 'all 0.45s ease',
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  entering: {
    opacity: 0,
    transform: 'translate(-20px,20px)',
  },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}))

const IndexTemplate = ({
  texture,
  episodes,
  series,
  displayFeaturedSeries,
}) => {
  const { node: heroSeries } = series.edges.slice(0, 1)[0]
  const featuredSeries = series.edges.slice(1, 4)
  const heroSeriesType = heroSeries.fields.content.reduce(
    (acc, { frontmatter: { templateKey } }) => {
      if (TYPES[templateKey] === acc) return acc
      return 'mixed'
    },
    TYPES[heroSeries.fields.content[0].frontmatter.templateKey],
  )
  const newReleases = episodes.edges.map((episode) => {
    const updatedEpisode = { ...episode }
    const matchingSeries = series.edges.filter(
      (seriesItem) => seriesItem.node.frontmatter.content.indexOf(
        episode.node.frontmatter.id,
      ) >= 0,
    )
    const matchingSeriesTitle = matchingSeries.length > 0 ? matchingSeries[0].node.frontmatter.title : ''
    updatedEpisode.node.fields.seriesTitle = matchingSeriesTitle
    updatedEpisode.node.fields.seriesTitleSlug = matchingSeries.length > 0
      ? matchingSeries[0].node.fields.slug
      : ''
    return updatedEpisode
  })

  const Texture = makeTexture(texture)

  const getUrl = (featuredSeries) => {
    const selectedObject = featuredSeries[featuredSeries.length - 1].node.fields.content.find(
      (item) => item.frontmatter.id === featuredSeries[featuredSeries.length - 1].node.frontmatter.content[featuredSeries[featuredSeries.length - 1].node.frontmatter.content.length - 1]
      );  
      return selectedObject.frontmatter.externalUrl ? selectedObject.frontmatter.externalUrl : selectedObject.fields.slug
  }

  return (
    <Box overflow="hidden">
      <Container maxWidth="xl" pl={0} pr={0}>
        <Box display="flex" minHeight={{ xs: '87vh', md: '95vh' }}>
          <HomeHero
            texture={texture}
            title={heroSeries.frontmatter.title}
            titleUnderline={heroSeries.frontmatter.titleUnderline}
            description={heroSeries.frontmatter.description}
            buttons={[
              {
                id: heroSeries.frontmatter.id,
                type: heroSeriesType,
                text: `${heroSeriesType === 'read' ? 'Read' : 'Play'} Now`,
                to: `/series?title=${seriesTitleSlug(heroSeries.fields.slug)}`,
              },
            ]}
            slug={heroSeries.fields.slug}
            image={heroSeries.frontmatter.homepageImage && heroSeries.frontmatter
              .homepageImage !== null ? heroSeries.frontmatter.homepageImage
              : heroSeries.frontmatter.heroImg}
            type={heroSeriesType}
          />
        </Box>
      </Container>
      {displayFeaturedSeries ? (
        <Box>
          <IntersectBox type="riseTrigger" rootMargin="-40px">
            <Box
              display="flex"
              justifyContent="center"
              pt={8}
              className="rise-transition-item"
            >
              <Typography variant="h2">Featured Series</Typography>
            </Box>
          </IntersectBox>
          <FeaturedSeries
            texture={texture}
            series={featuredSeries}
          />
        </Box>
      ) : null}
      <Box
        pt={4}
        pb={18}
        display="flex"
        justifyContent="center"
        id="discover-series"
      >
        <RiseButton type="series" to={getUrl(featuredSeries)}>
          Discover Episode
        </RiseButton>
      </Box>
      <Box mb={8} id="new-releases" position="relative">
        <Box
          position="absolute"
          bottom="0"
          left="0"
          width="50%"
          maxWidth="630px"
          zIndex="10"
        >
          <Texture variation="grey" />
        </Box>
        <Box
          position="absolute"
          top="0"
          left="0"
          width="50%"
          maxWidth="630px"
          zIndex="10"
        >
          <Texture variation="grey" />
        </Box>
        <Box
          position="absolute"
          top="0"
          right="0"
          width="50%"
          maxWidth="630px"
          zIndex="10"
        >
          <Texture variation="grey" />
        </Box>
        <Box
          position="absolute"
          bottom="0"
          right="0"
          width="50%"
          maxWidth="630px"
          zIndex="10"
        >
          <Texture variation="grey" />
        </Box>
        <NewReleases content={newReleases} />
      </Box>
    </Box>
  )
}

IndexTemplate.propTypes = {
  texture: PropTypes.shape({
    blue: PropTypes.shape({}),
    green: PropTypes.shape({}),
    grey: PropTypes.shape({}),
  }).isRequired,
  displayFeaturedSeries: PropTypes.bool.isRequired,
  episodes: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.shape({
            heroImg: PropTypes.shape({}),
          }),
        }),
      }),
    ),
  }).isRequired,
  series: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.shape({}),
        }),
      }),
    ),
  }).isRequired,
}

const IndexPage = ({
  data: {
    textureBlue, textureGreen, textureGrey, episodes, series,
  },
}) => {
  const classes = useStyles()
  const texture = {
    blue: textureBlue,
    green: textureGreen,
    grey: textureGrey,
  }

  useEffect(() => {
    window.dataLayer.push({ page: null })
    window.dataLayer.push({
      event: 'page_view',
      page: {
        '@type': 'Homepage',
        breadcrumb: 'Home',
        id: 'home',
        name: 'Home Page',
        page_category: 'Homepage',
      },
    })
  }, [])

  return (
    <Layout hasLoader>
      <TransitionState>
        {({ transitionStatus }) => (
          <Box className={`${classes.main} ${classes[transitionStatus]}`}>
            <IndexTemplate
              texture={texture}
              episodes={episodes}
              series={series}
              displayFeaturedSeries
            />
            <AboutBlock texture={texture} />
            <NewsletterBlock texture={texture} />
          </Box>
        )}
      </TransitionState>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    textureBlue: PropTypes.shape({}).isRequired,
    textureGreen: PropTypes.shape({}).isRequired,
    textureGrey: PropTypes.shape({}).isRequired,
    episodes: PropTypes.shape({}).isRequired,
    series: PropTypes.shape({}).isRequired,
  }).isRequired,
}

export const pagesQuery = graphql`
  query allPages {
    textureBlue: file(relativePath: { eq: "textureblue.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    textureGreen: file(relativePath: { eq: "texturegreen.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    textureGrey: file(relativePath: { eq: "texturegrey.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    episodes: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { in: ["Audio", "Video", "MixedMedia"] }
          hidePost : {ne : true}
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
            autoEpisodeNumber
          }
          frontmatter {
            id
            title
            templateKey
            content
            description
            mediaDuration
            publisher
            tags
            externalUrl
            heroImg {
              childImageSharp {
                fluid(maxWidth: 686, maxHeight: 386) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    series: allMarkdownRemark(
      filter: {
        fields: { slug: {regex: "/\\/series\\//" },
      }},
      sort: {fields: fields___autoSeriesNumber} ) {
      edges {
        node {
          fields {
            slug
            content {
              id
              fields {
                slug
              }
              frontmatter {
                id
                title
                templateKey
                externalUrl
                hidePost
              }
            }
          }
          frontmatter {
            id
            heroImg {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            homepageImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            titleUnderline
            description
            templateKey
            content
          }
        }
      }
    }
  }
`

export { IndexTemplate }
export default IndexPage
