import React, { useRef } from 'react'
import { PropTypes } from 'prop-types'
import { Box, withTheme, makeStyles } from '@material-ui/core'
import Circle from './Circle'
import Triangle from './Triangle'

const useStyles = makeStyles((theme) => ({
  case1: {
    top: '25%',
    left: '-25%',
    width: '40%',
    [theme.breakpoints.up('md')]: {
      top: '22%',
      left: '12%',
      width: '20%',
    },
  },
  case2: {
    top: '0',
    right: '-11%',
    width: '53%',
    [theme.breakpoints.up('md')]: {
      top: '-10%',
      right: '12%',
      width: '35%',
    },
  },
  case3: {
    top: '50%',
    left: '-35%',
    width: '50%',
    [theme.breakpoints.up('md')]: {
      top: '-5%',
      left: '15%',
      width: '22%',
    },
  },
  square: {
    position: 'absolute',
    top: '10%',
    left: '95%',
    width: '250px',
    height: '250px',
    background: theme.palette.primary.secondary,
    borderRadius: '10%',
    transform: 'rotate(-155deg)',
    opacity: '0.5',
    [theme.breakpoints.up('md')]: {
      top: '30%',
      left: '110%',
      width: '450px',
      height: '450px',
    },
  },
  listen: {
    fill: theme.palette.primary.main,
  },
  watch: {
    fill: theme.palette.primary.secondary,
  },
  read: {
    fill: theme.palette.primary.tertiary,
  },
  mixed: {
    fill: theme.palette.primary.mixed,
  },
}))

const Shape = ({ theme, name, className }) => {
  const classes = useStyles()
  const shapeRef = useRef(null)
  switch (name) {
    case 2:
      return (
        <>
          <Box position="absolute" className={`${classes.case3} ${className}`}>
            <Box ref={shapeRef}>
              <Circle color={theme.palette.primary.tertiary} />
            </Box>
          </Box>
          <Box className={classes.square} />
        </>
      )
    case 1:
      return (
        <Box position="absolute" className={`${classes.case2} ${className}`}>
          <Box ref={shapeRef}>
            <Triangle color={theme.palette.primary.main} />
          </Box>
        </Box>
      )
    case 0:
    default:
      return (
        <Box position="absolute" className={`${classes.case1} ${className}`}>
          <Box ref={shapeRef}>
            <Circle color={theme.palette.primary.secondary} />
          </Box>
        </Box>
      )
  }
}

Shape.propTypes = {
  name: PropTypes.number.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string,
        secondary: PropTypes.string,
        tertiary: PropTypes.string,
      }),
    }),
  }).isRequired,
  className: PropTypes.string.isRequired,
}

export default withTheme(Shape)
