import React from 'react'
import { PropTypes } from 'prop-types'
import { Box, Typography, makeStyles } from '@material-ui/core'

import EyebrowWithType from './EyebrowWithType'

const useStyles = makeStyles((theme) => ({
  main: {
    transform: 'scale(0.98)',
    transition: 'transform 0.4s ease',
    '&:hover': {
      transform: 'scale(1)',
    },
    '&:hover $listen': {
      color: theme.palette.primary.main,
    },
    '&:hover $watch': {
      color: theme.palette.primary.secondary,
    },
    '&:hover $read': {
      color: theme.palette.primary.tertiary,
    },
  },

  listen: { transition: 'color 0.2s ease' },
  watch: { transition: 'color 0.2s ease' },
  read: { transition: 'color 0.2s ease' },
}))

const CardCopy = ({
  className,
  content: { title, description },
  themeType,
}) => {
  const classes = useStyles()
  return (
    <Box
      className={`${className} ${classes.main}`}
      mx={{ xs: 2, md: 0 }}
      py={{ xs: 3.75, md: 5 }}
      px={{ xs: 2.5, md: 8.75 }}
    >
      <EyebrowWithType type={themeType} mb={1.5} />
      <Typography variant="h3" className={classes[themeType]}>
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Box>

  )
}

CardCopy.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.shape({
    // templateKey: PropTypes.oneOf(['listen', 'read', 'watch', 'mixed']).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  themeType: PropTypes.string.isRequired,
}

export default CardCopy
