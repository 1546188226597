import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import './ImageRiseHero.scss'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  main: {
    width: '80vw',
    height: '75vh',
    maxHeight: '60vw',
    minHeight: '600px',
    background: 'transparent',
    willChange: 'transform',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      height: '40vh',
      minHeight: 'auto',
      maxHeight: 'initial',
    },
  },
  listen: {
    fill: theme.palette.primary.main,
  },
  watch: {
    fill: theme.palette.primary.secondary,
  },
  read: {
    fill: theme.palette.primary.tertiary,
  },
  mixed: {
    fill: theme.palette.primary.mixed,
  },
}))

const MaskedImage = ({
  className, uniqueId, isActive, themeType, image, imagePosition,
}) => {
  const containerRef = useRef(null)

  const classes = useStyles()
  return (
    <Box
      position="relative"
      ref={containerRef}
      className={`image-rise-hero ${className}`}
    >
      <Box className="svg-container">
        <svg
          className={`animated-svg ${classes.main}
            hover-enabled
           ${isActive ? 'active' : ''}`}
          viewBox="0 10 100 100"
        >
          <mask id={`masked-image-mask-${uniqueId}`}>
            <g id={`rect-container${uniqueId}`}>
              <rect x="0" y="0" width="120" height="120" fill="black" />
            </g>
            <g id={`group-mask${uniqueId}`} className="group-mask" opacity={1}>
              <g className="rect-container one">
                <rect
                  x="20"
                  y="15"
                  width="1.6em"
                  height="5em"
                  rx="4"
                  fill="white"
                />
              </g>
              <g className="rect-container two">
                <rect
                  x="45"
                  y="0"
                  width="1.6em"
                  height="5em"
                  rx="4"
                  fill="white"
                />
              </g>
              <g className="rect-container three">
                <rect
                  x="72"
                  y="-13"
                  width="1.6em"
                  height="5em"
                  rx="4"
                  fill="white"
                />
              </g>
            </g>
          </mask>
          <g className="theme-bars" opacity={1}>
            <g className="rect-container one theme-bar">
              <rect
                x="20.75"
                y="15.75"
                width="1.6em"
                height="5em"
                rx="4"
                className={classes[themeType]}
              />
            </g>
            <g className="rect-container two theme-bar">
              <rect
                className={classes[themeType]}
                x="45.75"
                y="0.75"
                width="1.6em"
                height="5em"
                rx="4"
                fill="#07a"
              />
            </g>
            <g className="rect-container three theme-bar">
              <rect
                x="72.75"
                y="-12.25"
                width="1.6em"
                height="5em"
                rx="4"
                className={classes[themeType]}
              />
            </g>
          </g>
          <image
            id={`masked-image${uniqueId}`}
            fill="#000"
            width="11.52em"
            height="6.48em"
            x={imagePosition.x}
            y={imagePosition.y}
            preserveAspectRatio="none"
            xlinkHref={image.childImageSharp.fluid.src}
            mask={`url(#masked-image-mask-${uniqueId})`}
          />
        </svg>
      </Box>
    </Box>
  )
}

MaskedImage.propTypes = {
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({ src: PropTypes.string }),
    }),
  }).isRequired,
  imagePosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  themeType: PropTypes.oneOf(['listen', 'read', 'watch', 'mixed']).isRequired,
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  uniqueId: PropTypes.string.isRequired,
}

MaskedImage.defaultProps = {
  imagePosition: {
    x: -20,
    y: 10,
  },
}

export default MaskedImage
