import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Typography, Box, makeStyles } from '@material-ui/core'

import { withEllipsis } from '../utils/string'
import IntersectBox from './IntersectBox'

const useStyles = makeStyles((theme) => ({
  main: {
    '&$active $word': {
      opacity: 1,
    },
    '&$active $titleContainer': {
      transform: 'translate(0px,0px)',
    },
    '&$active $descriptionContainer': {
      opacity: 1,
      transform: 'translate(0px,0px)',
    },
    '&$active $underlineContainer': {
      width: '100%',
    },
  },
  heroTitle: {
    fontFamily: 'permanent_markerregular',
    fontSize: '3.125rem',
    lineHeight: '125%',

    [theme.breakpoints.up('lg')]: {
      fontSize: '4.5rem',
    },
  },
  titleContainer: {
    transform: 'translate(-20px,20px)',
    transition: 'transform 0.9s ease',
  },
  word: {
    opacity: 0,
    transition: 'opacity 0.9s ease',
  },
  active: {},
  descriptionContainer: {
    opacity: 0,
    transform: 'translate(0px,8px)',
    transition: 'all 0.6s ease',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(5.75),
    },
  },
  description: {
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
    },
  },
  underline: {
    backgroundImage: 'url("/Underline.svg")',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    color: '#f2f3f8',
  },
  underlineContainer: {
    width: '0%',
    top: 0,
    overflow: 'hidden',
    transition: 'all 0.6s ease',
  },
}))

const TitleHighlight = ({
  title,
  highlightIndex,
  breakIndex,
  description,
  shouldExit,
}) => {
  const classes = useStyles()
  const [titleVisible, setTitleVisible] = useState(false)

  return (
    <Box
      style={{ transform: 'translateZ(0)' }}
      className={`${classes.main} ${titleVisible ? classes.active : ''}`}
    >
      <IntersectBox
        allowTriggerReset
        onThresholdMet={() => setTitleVisible(true)}
        onExitThreshold={() => (shouldExit ? setTitleVisible(false) : null)}
      >
        <Box className={classes.titleContainer} style={{ maxWidth: '675px' }}>
          <Typography className={classes.heroTitle} variant="h1">
            {title.split(' ').map((word, index) => (index === highlightIndex ? (
              <span
                key={word}
                className={`${classes.word}`}
                style={{
                  position: 'relative',
                  transitionDelay: `${titleVisible ? index * 200 : 0}ms`,
                  paddingBottom: '5px',
                }}
              >
                <span
                  className={classes.underlineContainer}
                  style={{
                    position: 'absolute',
                    height: '100%',
                    transitionDelay: `${
                      titleVisible
                        ? index * 200 + title.split(' ').length * 200
                        : 0
                    }ms`,
                  }}
                >
                  <span
                    className={`${classes.underline} ${classes.word}`}
                    style={{
                      transitionDelay: `${titleVisible ? index * 200 : 0}ms`,
                      position: 'absolute',
                      height: '100%',
                    }}
                  >
                    <span style={{ opacity: 0 }}>
                      {word}
                      {' '}
                    </span>
                  </span>
                </span>
                {word}
                {' '}
              </span>
            ) : (
              <span
                key={word}
                className={classes.word}
                style={{
                  transitionDelay: `${titleVisible ? index * 200 : 0}ms`,
                }}
              >
                {`${word} `}
                {' '}
                {index === breakIndex ? <br /> : ''}
              </span>
            )))}
          </Typography>
        </Box>
      </IntersectBox>
      {description && (
        <Box
          maxWidth={550}
          style={{
            transitionDelay: `${(title.split(' ').length + 1) * 280}ms`,
          }}
          className={classes.descriptionContainer}
        >
          <Typography className={classes.description} variant="body1">
            {withEllipsis(description, 285)}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

TitleHighlight.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  shouldExit: PropTypes.bool,
  highlightIndex: PropTypes.number,
  breakIndex: PropTypes.number,
}

TitleHighlight.defaultProps = {
  shouldExit: false,
  description: '',
  highlightIndex: 0,
  breakIndex: null,
}

export default TitleHighlight
