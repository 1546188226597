import React, { useState, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import TransitionLink from 'gatsby-plugin-transition-link'
import CardCopy from './CardCopy'
import Shape from './graphics/FeaturedSeriesShape'
import MaskedImage from './animated-images/MaskedImage'
import IntersectBox from './IntersectBox'
import { TYPES } from '../utils/constants'
import makeTexture from './graphics/Texture'

const useStyles = makeStyles((theme) => ({
  main: {},
  featuredCardContainer: {
    opacity: 0,
    transition: 'all 1s ease',
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      transform: 'translateY(50%)',
      position: 'absolute',
      top: '50%',
    },
  },
  featuredCardCopy: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderRadius: '30px',
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 20px',
    },
  },
  seriesItem: {
    margin: '0 auto',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    '&:nth-child(even) $imageContainer': {
      transform: 'translate(20%,0)',
      [theme.breakpoints.down('sm')]: {
        transform: 'translate(0%,0)',
      },
    },
    '&:nth-child(odd) $imageContainer': {
      transform: 'translate(-20%,0)',
      [theme.breakpoints.down('sm')]: {
        transform: 'translate(0%,0)',
      },
    },

    '&$active $featuredCardContainer': {
      opacity: 1,
      transform: 'translate(0%,-40px)',
      [theme.breakpoints.up('md')]: {
        transform: 'translateY(0%)',
      },

    },
    '&$active $image': {
      opacity: 1,
      transform: 'translate(0px,0px)',
    },
    '&$active $shape': {
      opacity: 0.5,
      transform: 'translate(0,0%)',
    },
  },
  active: {},
  imageContainer: {},
  image: {
    transition: 'all 0.7s ease',
    opacity: 0,
    transform: 'translate(-30px,30px)',
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(-15px,15px)',
    },
  },
  shape: {
    opacity: 0,
    transform: 'translate(0,50%)',
    transition: 'all 0.9s ease',
  },
}))

const getUpperTextureColors = (idx) => {
  switch (idx) {
    case 2:
      return [{ id: '0', color: 'grey' }, { id: '1', color: 'grey' }]
    case 1:
      return [{ id: '2', color: 'blue' }]
    case 0:
    default:
      return [{ id: '3', color: 'grey' }, { id: '4', color: 'grey' }]
  }
}

const getUrl = (item) => {

  const selectedObject = item.node.fields.content.find((episode) => 
    episode.frontmatter.id === item.node.frontmatter.content[item.node.frontmatter.content.length - 1]
  )

  return selectedObject.frontmatter.externalUrl
    ? selectedObject.frontmatter.externalUrl
    : selectedObject.fields.slug
}

const debug = false
const FeaturedSeries = ({ series, texture }) => {
  const seriesTypes = series.map((item) => item.node.fields.content
    .reduce((acc, { frontmatter: { templateKey } }) => {
      if (TYPES[templateKey] === acc) return acc
      return 'mixed'
    }, TYPES[item.node.fields.content[0].frontmatter.templateKey]))
  const classes = useStyles()
  const cardRef = useRef(null)
  const imageRef = useRef(null)
  const [activeItems, setActiveItems] = useState(series.map(() => false))
  const [activeMaskedItems, setActiveMaskedItems] = useState(
    series.map(() => false),
  )
  const Texture = makeTexture(texture)

  return (
    <Box
      display="flex"
      flexDirection="column"
      pb={8}
    >
      {series.map((item, i) => {
        const upperTextureColorsRef = getUpperTextureColors(i)

        return (
          <IntersectBox
            className={[
              classes.seriesItem,
              activeItems[i] ? classes.active : null,
            ].join(' ')}
            key={item.node.id}
            position="relative"
            mb={6.5}
            maxWidth={1245}
            width="100%"
            display="flex"
            justifyContent="center"
            threshold={0.5}
            allowTriggerReset
            onThresholdMet={() => {
              const newItems = [...activeItems]
              newItems[i] = true
              setActiveItems(newItems)
            }}
            exitThreshold={0.05}
            onExitThreshold={() => {
              const newItems = [...activeItems]
              newItems[i] = false
              setActiveItems(newItems)
            }}
          >
            <Box className={classes.imageContainer} ref={imageRef}>
              {upperTextureColorsRef.map(({ id, color }) => (
                <Box
                  key={id}
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  maxWidth="800px"
                  zIndex="10"
                >
                  <Texture variation={color} />
                </Box>
              ))}
              <Box className="parallax-item">
                <MaskedImage
                  uniqueId={item.node.frontmatter.id}
                  image={item.node.frontmatter.homepageImage && item.node.frontmatter
                    .homepageImage !== null ? item.node.frontmatter.homepageImage
                    : item.node.frontmatter.heroImg}
                  imagePosition={{ x: i === 0 ? -60 : -20, y: 10 }}
                  className={classes.image}
                  themeType={seriesTypes[i]}
                  isActive={activeMaskedItems[i]}
                />
              </Box>

              <Shape className={classes.shape} name={i} />
            </Box>

            <Box
              className={classes.featuredCardContainer}
              maxWidth={509}
              width="100%"
              onMouseEnter={() => {
                const newItems = [...activeMaskedItems]
                newItems[i] = true
                setActiveMaskedItems(newItems)
              }}
              onMouseLeave={() => {
                const newItems = [...activeMaskedItems]
                newItems[i] = false
                setActiveMaskedItems(newItems)
              }}
              style={{ background: debug ? 'rgba(255,0,0,0.3)' : 'transparent' }}
              ref={cardRef}
            >
              <TransitionLink
                // eslint-disable-next-line max-len
                to={getUrl(item)}
                exit={{
                  length: 0.6,
                  delay: 0,
                  zIndex: 2,
                }}
                entry={{
                  delay: 0.6,
                  length: 1,
                }}
                style={{ textDecoration: 'none', color: 'inital' }}
                className="featured-series"
                data-series-name={item.node.frontmatter.title}
              >
                <Box>
                  <CardCopy
                    className={classes.featuredCardCopy}
                    content={item.node.frontmatter}
                    themeType={seriesTypes[i]}
                  />
                </Box>
              </TransitionLink>
            </Box>
          </IntersectBox>
        )
      })}
    </Box>
  )
}

FeaturedSeries.propTypes = {
  texture: PropTypes.shape({
    blue: PropTypes.shape({}),
    green: PropTypes.shape({}),
    grey: PropTypes.shape({}),
  }).isRequired,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default FeaturedSeries
