import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { TimelineLite } from 'gsap/all'
import './ImageRiseHero.scss'
import { withStyles } from '@material-ui/core/styles'
import GatsbyImage from 'gatsby-image'
import { HAS_FILTER } from '../../utils/constants'

const styles = (theme) => ({
  main: {
    height: '100%',
    width: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    background: 'transparent',
    willChange: 'transform',
    [theme.breakpoints.up('sm')]: {
      height: '80vh',
      maxHeight: '80vh',
      minHeight: '80vh',
    },
    [theme.breakpoints.up('md')]: {
    },
    [theme.breakpoints.up('lg')]: {
      width: '120%',
      height: '90vh',
      maxHeight: '75vw',
      minHeight: '700px',
    },
  },
})

const getProgress = ({ elapsed, total }) => Math.max(0, Math.min(elapsed / total, 1))

const easeQuad = (n) => n * (2 - n)

class ImageRiseHomeHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPlaying: false,
      isFinished: false,
    }
    this.canAnimate = true
    this.filterTriggered = false
    this.maskContainerRef = React.createRef()
    this.containerRef = React.createRef()
    this.svgRef = React.createRef()
    this.maskBar1Ref = React.createRef()
    this.maskBar2Ref = React.createRef()
    this.maskBar3Ref = React.createRef()
    this.turbRef = React.createRef()
    this.animElms = [
      {
        ref: this.maskBar3Ref,
        start: { x: 81, y: 16 },
        end: { x: 69, y: 16 },
        ti: 2,
      },
      {
        ref: this.maskBar2Ref,
        start: { x: 43, y: 30 },
        end: { x: 43, y: 0 },
        ti: 1,
      },

      {
        ref: this.maskBar1Ref,
        start: { x: 4, y: 10 },
        end: { x: 17, y: 10 },
        ti: 0,
      },
    ]
  }

  componentDidMount() {
    this.tick = this.tick.bind(this)
    this.startAnimation = this.startAnimation.bind(this)

    this.observerElements = null
  }

  componentWillUnmount() {
    this.canAnimate = false
    if (this.observerElements) {
      this.observerElements.forEach((el) => {
        el.observer.unobserve(el)
      })
    }
  }

  tick = (now) => {
    if (!this.canAnimate) return
    const { time1, animElms } = this
    time1.elapsed = now - time1.start
    const progress1 = getProgress(time1)
    const easing1 = easeQuad(progress1)

    const tiX = easing1
    const tiY = easing1
    for (let i = 0; i < animElms.length; i += 1) {
      const posX = tiX * (animElms[i].end.x - animElms[i].start.x) + animElms[i].start.x
      const posY = tiY * (animElms[i].end.y - animElms[i].start.y) + animElms[i].start.y
      animElms[i].ref.current.setAttribute('x', posX)
      animElms[i].ref.current.setAttribute('y', posY)
    }
    this.maskContainerRef.current.style.transition = 'none'

    if (progress1 < 1) requestAnimationFrame(this.tick)

    if (progress1 === 1) {
      this.setState({ isPlaying: false, isFinished: true })
      this.maskContainerRef.current.style.transition = 'all 0.5s ease'
    }
  }

  startAnimation = () => {
    this.setState({ isPlaying: true })

    this.time1 = {
      start: performance.now(),
      total: 1100,
    }

    requestAnimationFrame(this.tick)
    if (HAS_FILTER) {
      const turbVal = { val: 0.3 }
      const turb = this.turbRef.current

      const btTl = new TimelineLite({
        paused: false,
        onUpdate() {
          turb.setAttribute('scale', turbVal.val)
        },
      })

      btTl
        .to(turbVal, 0.01, { val: 7 })
        .to(turbVal, 0.3, { val: 7 })
        .to(turbVal, 0.8, { val: 0.01 })
        .to(turbVal, 0.6, { val: 0.000001 })

      btTl.play()
    }
  }

  render() {
    const {
      classes, hasEnterAnim, isActive, image, onHeroImageLoad,
    } = this.props
    const { isPlaying, isFinished } = this.state
    const opacity = !hasEnterAnim || (hasEnterAnim && (isPlaying || isFinished)) ? 1 : 0

    return (
      <Box
        position="relative"
        className="image-rise-hero"
        style={{ opacity: `${opacity}`, transition: 'opacity 0.9s ease' }}
        ref={this.containerRef}
      >
        <GatsbyImage
          style={{ display: 'none' }}
          title="string value..."
          fluid={image.childImageSharp.fluid}
          loading="eager"
          onLoad={() => onHeroImageLoad()}
        />
        <svg
          className={`animated-svg ${classes.main} ${
            isPlaying ? 'is-playing' : 'hover-enabled'
          } ${isFinished ? 'is-finished' : ''} ${isActive ? 'active' : ''} ${
            hasEnterAnim ? 'will-enter' : ''
          }`}
          viewBox="-5 12 100 100"
          ref={this.svgRef}
        >
          {' '}
          {HAS_FILTER && (
          <filter id="noiseHome" x="0%" y="0%" width="100%" height="100%">
            <feTurbulence
              type="fractalNoise"
              baseFrequency="0 0.4"
              result="NOISE"
              numOctaves="2"
            />
            <feDisplacementMap
              ref={this.turbRef}
              in="SourceGraphic"
              in2="NOISE"
              scale="0"
              xChannelSelector="R"
              yChannelSelector="R"
            />
          </filter>
          )}
          <defs>
            <linearGradient id="black-gradient">
              <stop offset="0%" stopColor="#0C0F11" />
              <stop offset="50%" stopColor="#0C0F11" stopOpacity="0" />
            </linearGradient>
          </defs>
          <mask id="myMask">
            <g id="rect-container">
              <rect x="0" y="0" width="120" height="120" fill="black" />
            </g>
            <g className="group-mask shift" ref={this.maskContainerRef}>
              <g className="rect-container one">
                <rect
                  x="17"
                  y="10"
                  width="1.4em"
                  height="4em"
                  rx="4"
                  fill="white"
                  ref={this.maskBar1Ref}
                />
              </g>
              <g className="rect-container two">
                <rect
                  x="43"
                  y="0"
                  width="1.4em"
                  height="5em"
                  rx="4"
                  fill="white"
                  ref={this.maskBar2Ref}
                />
              </g>
              <g className="rect-container three">
                <rect
                  x="69"
                  y="4"
                  width="1.4em"
                  height="3.08em"
                  rx="4"
                  fill="white"
                  ref={this.maskBar3Ref}
                />
              </g>
            </g>
          </mask>
          <image
            id="hero-image"
            fill="#000"
            width="7em"
            height="6.4em"
            x="-21"
            y="18"
            xlinkHref={image.childImageSharp.fluid.src}
            mask="url(#myMask)"
            filter={`${HAS_FILTER ? 'url(#noiseHome)' : ''}`}
          />
          <rect
            x="-50"
            y="0"
            width="160"
            height="120"
            style={{ fill: 'url(#black-gradient)' }}
          />
        </svg>
      </Box>
    )
  }
}

ImageRiseHomeHero.propTypes = {
  classes: PropTypes.shape(styles).isRequired,
  image: PropTypes.shape(PropTypes.image).isRequired,
  hasEnterAnim: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onHeroImageLoad: PropTypes.func.isRequired,
}

export default withStyles(styles, { withTheme: true })(ImageRiseHomeHero)
